<script setup lang="ts"></script>

<template>
    <div>
        <NuxtLink href="/sales/deeplink/campaign">
            <Button variant="secondaryLight" class="w-full mb-5">แคมเปญ</Button>
        </NuxtLink>
        <NuxtLink href="/sales/deeplink/contact">
            <Button variant="secondaryLight" class="w-full">ผู้ติดต่อ</Button>
        </NuxtLink>
    </div>
</template>
